
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'IconArrowLeft',
  props: {
    side: {
      type: String,
      default: () => 'bottom',
    },
    width: {
      type: String,
      default: () => 's',
    }
  },
  computed: {
    classList(): Array<string> {
      return [
        'icon-arrow-left',
        `icon-arrow-left_side_${this.side}`,
        `icon-arrow-left_width_${this.width}`
      ]
    }
  }
})
