
import {defineComponent} from 'vue';

export default defineComponent({
  inheritAttrs: false,
  name: 'ModalWrapper',
  emits: ['close'],
  props: {
    title: {
      type: String,
      default: () => null
    },
    id: {
      type: String,
      default: () => null
    },
    unsetWidth: {
      type: Boolean,
      default: () => false
    },
    unsetHeight: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    classList(): any {
      return [
        'modal',
        this.unsetWidth && 'modal_unset_width',
        this.unsetHeight && 'modal_unset_height',
      ]
    }
  },
  setup(props, {emit}) {
    function closeModal() {
      let element = document.getElementById(`modal-${props.id}`)!;
      element.style.display = 'none'
      emit('close')
    }

    return {closeModal}
  }
})
