
import { defineComponent } from 'vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import router from '@/router';

export default defineComponent({
  name: 'VehicleSideBar',
  components: {
    BaseIcon
  },
  props: {
    text: {
      type: String,
      default: () => ''
    },
    vehicleArticlesCount: {
      type: Number,
      default: () => 0
    },
    tabs: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    setActiveTab(item: any) {
      this.$emit('changeTab', item)
    }
  },
  setup() {
    function goToBack() {
      router.go(-1)
    }
    return {goToBack}
  }
})
