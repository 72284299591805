
import {defineComponent, ref, computed, watch, onMounted} from 'vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import debounce from "@/components/debounce";

export default defineComponent({
  name: 'BaseInput',
  emits: ['update', 'clear', 'focus'],
  components: {
    BaseIcon
  },
  props: {
    showCloseIcon: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'text'
    },
    borderBottom: {
      type: Boolean,
      default: () => false
    },
    text: {
      type: String,
      default: () => ''
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    placeholder: {
      type: String,
      default: () => ''
    },
    height: {
      type: String,
      default: () => ''
    }
  },
  setup(props, {emit}) {
    const inputText = ref('' as string);
    const getRandomId = computed(() => Math.random().toString(36).substring(2));

    watch(() => props.text, (val) => {
      // if (typeof val === 'string') inputText.value = val
      inputText.value = val
    });

    function setDefaultData() {
      inputText.value = props.text ? props.text : ''
    }

    function update(item: any) {
      emit('update', item.target.value)
    }

    function setActiveStyle(item: any) {
      console.log('focus')
    }

    function setFocus() {
      emit('focus')
    }

    function clearInput() {
      inputText.value = '';
      (document.getElementById(`input-${getRandomId.value}`) as HTMLInputElement).focus();
      emit('update', '')
      emit('clear')
    }

    onMounted(() => {
      setDefaultData()
    });

    return {inputText, getRandomId, update, clearInput, setActiveStyle, setFocus, ...debounce()}
  }
})
