
import { defineComponent, ref, computed, watch } from 'vue';
import {useStore} from '@/store'
import BaseIcon from '@/components/ui/BaseIcon.vue';
import Images from './modal-blocks/Images.vue'

export default defineComponent({
  name: 'ImageView',
  emits: ['upload', 'delete'],
  components: {
    BaseIcon,
    Images,
  },
  props: {
    data: Array,
    default: () => [],
  },
  setup(props, { emit }) {
    const store = useStore();
    const view: { [x: string]: any } = ref({ name: '', value: [] }); // Текущее отображение (Фото / Чертежи)
    const index = ref(0 as number); // Текущий индекс отображения

    const getTabs = computed(() => {
      if (props && props.data) {
        return props.data.map((item: any) => ({
          name: item.name,
          value: item.value,
          ...(item && item.key ? { key: item.key } : {})
        }))
      }
    });

    const isImage = computed(() => !!view.value.value.length && /\.(png|jpe?g|gif|svg)(\?.*)?$/.test(view.value.value[index.value]));
    const imageSrc = computed(() => view.value.value[index.value])
    const getRandomId = computed(() => Math.random().toString(36).substring(2));

    const checkUserRoleForAddImage = computed(() => {
      return store.getters.getAllPermissions.edit_article[store.getters.getUserRole]
    })

    function setTab(item: { [x: string]: string }) {
      index.value = 0;
      view.value = item
    }

    function openModal() {
      let element = document.getElementById(`modal-images-${getRandomId.value}`)!;
      element.style.display = 'flex'
    }

    function closeModal() {
      let element = document.getElementById(`modal-images-${getRandomId.value}`)!;
      element.style.display = 'none'
    }

    async function deleteFile(idx: number) {
      let file: { [x: string]: any } = view.value.value[idx];

      try {
        emit('delete', view.value, file)

        if (idx > 0 && index.value === idx) {
          index.value = --index.value
        }
        closeModal()
      } catch (error) {
        closeModal();
        throw error
      }
    }

    async function uploadFiles(e: any) {
      const files = e.target.files || e.dataTransfer.files
      emit('upload', view.value, files)
    }

    function pickFiles() {
      (document.getElementById(`input-${getRandomId.value}`) as HTMLInputElement).click()
    }

    function next() {
      if (index.value < view.value.value.length - 1) {
        index.value += 1
      }
    }

    function previous() {
      if (index.value) index.value--
    }

    //@ts-ignore
    watch(() => props.data, async (dataView: Array<{name: string, value: Array<string>}>) => {
      if (!view.value.name.length) {
        const [ defaultView ] = props.data as Array<{name: string, value: Array<string>}>
        view.value = defaultView
      } else {
        view.value = dataView.find((value: { name: string, value: Array<string> }) => {
          return value.name === view.value.name
        })
      }
    }, { deep: true, immediate: true });

    return {
      view,
      index,
      getTabs,
      isImage,
      imageSrc,
      getRandomId,
      setTab,
      openModal,
      closeModal,
      deleteFile,
      uploadFiles,
      pickFiles,
      next,
      previous,
      checkUserRoleForAddImage,
    }
  }
})
