import {httpClient} from '@/httpClient';

async function getAllCountries() {
  return await httpClient.get(`/countries`).then((response: any) => {
    return response.data.map((country: {id: number, name: string}) => country.name)
  })
}

function getCountryByName() {
  return httpClient.get(`/countries`).then((response) => response.data)
}

function addRegion(data: any) {
  return httpClient.post(`/product/vehicles/regions`, data)
}

export default {
  getAllCountries,
  getCountryByName,
  addRegion
};
