
import { defineComponent, ref, computed, watch, nextTick } from 'vue';
import {useStore} from '@/store'
import BaseIcon from '@/components/ui/BaseIcon.vue';
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import SelectionSlot from '@/components/modals/SelectionSlot.vue'
import {roles} from "@/services/roles/serviceRoles";

export default defineComponent({
  name: 'Images',
  emits: ['close-modal', 'delete-file'],
  components: {
    BaseIcon,
    ModalWrapper,
    SelectionSlot
  },
  props: {
    content: {
      type: Array,
      default: () => []
    },
    index: {
      type: Number,
      default: () => 0
    },
    id: {
      type: String,
      default: () => ''
    },
  },
  setup(props, {emit}) {
    const store = useStore();
    const array: { [x: string]: any } = ref([]);
    const subscript = ref(0 as number);
    const isImage = computed(() => /\.(png|jpe?g|gif|svg)(\?.*)?$/.test(array.value[subscript.value]));
    const imageSrc = computed(() => {
      return array.value[subscript.value]
    });
    const getRandomId = computed(() => Math.random().toString(36).substring(2));

    const checkUserRoleForDeleteImage = computed(() => {
      return store.getters.getAllPermissions.edit_article[store.getters.getUserRole]
    })

    watch(() => props.content, (val) => {
      array.value = val
    }, {deep: true, immediate: true });
    watch(() => props.index, (val) => {
      subscript.value = val
    });

    function openDeleteModal() {
      let element = document.getElementById(`modal-${getRandomId.value}`)!;
      element.style.display = 'flex'
    }

    function closeDeleteModal() {
      let element = document.getElementById(`modal-${getRandomId.value}`)!;
      element.style.display = 'none'
    }

    function closeModal() {
      emit('close-modal')
    }

    function deleteFile() {
      closeDeleteModal();

      nextTick(() => {
        emit('delete-file', subscript.value)

        if (subscript.value > 0) {
          subscript.value -= 1
        }
      })
    }

    function next() {
      if (subscript.value < array.value.length - 1) subscript.value++
    }

    function previous() {
      if (subscript.value) subscript.value--
    }
    
    return {
      array,
      subscript,
      isImage,
      imageSrc,
      getRandomId,
      openDeleteModal,
      closeDeleteModal,
      closeModal,
      deleteFile,
      next,
      previous,
      checkUserRoleForDeleteImage
    }
  }
})
