
import Spinner from '@/components/Spinner.vue';
import { defineComponent } from 'vue';
import vehicles from '@/services/vehicles';
import BaseError from '@/components/ui/BaseError.vue';
import vehicleBrands from '@/services/vehicleBrands';
import vehicleModels from '@/services/vehicleModels';
import { Categories, Status } from '@/components/types/enums';
import SideBar from './VehicleSideBar.vue';
import routerService from '@/services/routerService';
import country from '@/services/country';
import CustomSelectModal from '@/components/ui/CustomSelectModal.vue';
import { ConvertDate } from '@/components/converters/date';
import { VehicleMutationTypes } from '@/store/modules/vehicles/mutation-types';
import VehicleParts from '@/components/vehicle-card/VehicleParts.vue';
import ImageView from '@/components/article-card/ImageView.vue';
import { mapActions, mapGetters } from 'vuex';
import { DataViewVehicleCard } from '@/models/VehicleModel';
import configuration from '../../../configuration';

export default defineComponent({
  name: 'VehicleCardWrapper',
  components: {
    ImageView,
    VehicleParts,
    CustomSelectModal,
    Spinner,
    SideBar,
    BaseError,
  },
  data: () => ({
    allModelsCurrentBrand: null as any,
    isLoading: false,
    vehicle: {} as any,
    activeTab: { key: 'BASIC', name: 'Основная информация' },
    tabs: [
      {key: 'BASIC', name: 'Основная информация'},
      {key: 'APPLICABILITY', name: `Запчасти ${ configuration.customer }`},
      {key: 'REGIONS', name: 'Статистика'}
    ],
    editMode: false,
    showError: false as boolean,
    vehicleArticles: [] as any,
    vehicleInfoEdit: {
      model: null as any,
      modelFull: null as any,
      modelId: null as any,
      vehYears: null as any, // выпуск
      bodyType: null as any,
      driveType: null as any,
      enginesL: null as any,
      fuelType: null as any,
      hp: null as any,
      // vehicle: null as any,
      customDesc: null as any,
      brandCustom: null as any,
      tecdocVehicleId: null as any,
      modBodyNum: null as any,
      motorCode: null as any,
    },
    isFileLoading: false as boolean,

    allRegions: [] as any,
    optionsRegions: [] as any,
    selectedRegion: null as any,
    quantityAllVehicles: null as any,

    allDates: [] as any,
    optionsDates: [] as any,
    selectedDate: null as any,

    selectedCountry: null as any,
    optionsCountry: [] as any,
    allCountries: [] as any,

    filter: {
      status: ['ACTIVE'],
    },
  }),
  refs: {
    sideBar: SideBar as any,
  },
  computed: {
    dataView(): Array<{name: string, value: Array<string>}> {
      return [this.$store.getters.dataView]
    },
    goToPage() {
      return async ({ articleId }: any) => {
        await routerService.setQuery(
          'last-selected-article',
          articleId,
          this.$route.query,
          this.$router
        )
        await this.$router.push({
          name: 'article',
          params: {
            id: articleId
          }
        })
      }
    },
    getVehicleCategory(): any {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return Categories[this.vehicle.model.category];
    },
    checkUserRole(): any {
      return this.$store.getters.getAllPermissions.edit_vehicles[this.$store.getters.getUserRole];
    },
    getInputClasses() {
      return (vehicleField: string) => [
        'input',
        vehicleField && vehicleField.length ? '' : 'required',
      ];
    },
    getVehicleTecdocId() {
      return () => this.vehicle.tecdocVehicleId;
    },
    getVehicleEngineCode() {
      return () => this.vehicle && this.vehicle.motor
    },
    getVehicleBrandCustom() {
      return () => this.vehicle.brandCustom;
    },
    getVehicleModelYears() {
      return () => this.vehicle.model.modelConstruction;
    },
    getVehicleDesc() {
      return (name: string) => this.vehicle.customDesc;
    },
    getVehicleName() {
      return (name: string) => `${this.vehicle.model.brand.brand} ${this.vehicle.model.model} ${this.vehicle.desc.modBodyNum ? `(${this.vehicle.desc.modBodyNum})` : ''}`;
    },
    getVehicleBrand() {
      return (item: string) => this.vehicle.model.brand.brand;
    },
    getVehicleModel() {
      return (item: string) => this.vehicle.model.model;
    },
    getVehicleYear() {
      return (item: string) => this.vehicle.desc.vehYears;
    },
    getVehicleBody() {
      return (item: string) => this.vehicle.desc.bodyType;
    },
    getVehicleDrive() {
      return (item: string) => this.vehicle.desc.driveType;
    },
    getVehicleModify() {
      return (item: string) => this.vehicle.desc.modBodyNum;
    },
    getVehicleEngineV() {
      return (item: string) => this.vehicle.desc.enginesL;
    },
    getVehicleFuel() {
      return (item: string) => this.vehicle.desc.fuelType;
    },
    getVehiclePower() {
      return (item: string) => this.vehicle.desc.hp;
    },
    convertEditDate() {
      return (item: string) => this.vehicle.lastUpdate.split('T')[0];
    },
    headerAutoPartsTable() {
      return [
        {
          title: 'Название',
          content: (item: any) => item.name
        },
        {
          title: 'Артикул',
          content: (item: any) => item.article
        },
        {
          title: 'Статус',
          content: (item: any) => {
            const statuses: any = Status;
            return statuses[item.status];
          }
        }
      ]
    }
  },
  methods: {
    async deleteImage(view: any, name: any) {
      await this.$store.dispatch('deleteImage', {
        vehicleId: this.vehicle.id,
        imageName: name,
        id: this.vehicle.id,
      })
    },
    async addImage(view: DataViewVehicleCard, files: FileList) {
      await this.$store.dispatch('addImage', {
        vehicleId: this.vehicle.id,
        files
      })
    },
    searchRegion(event: any) {
      this.optionsRegions = [...this.allRegions]
      this.optionsRegions = this.optionsRegions.filter(({region}: any) => region.toLowerCase().includes(event.target.value.toLowerCase()))
    },
    selectCountry(option: any) {
      this.selectedDate = null
      this.selectedRegion = null

      if (option === 'CLEAR_SELECTED') {
        this.selectedCountry = null
        this.optionsRegions = [...this.allRegions]
        this.optionsDates = [...this.allDates]
        return;
      }

      this.selectedCountry = option

      this.optionsRegions = [...this.allRegions.filter((region: any) => {
        return region.countryId === this.selectedCountry.id
      })]

      const allDateEveryRegion = this.optionsRegions.map((region: any) => ConvertDate(region.datetime))
      this.optionsDates = [...new Set(allDateEveryRegion)]

      this.quantityAllVehicles = this.optionsRegions.reduce((sum: any, current: any) => {
        return sum + current.count;
      }, 0)
    },
    selectDate(option: any) {
      this.selectedRegion = null

      if (option === 'CLEAR_SELECTED') {
        this.selectedDate = null
        this.optionsRegions = [...this.allRegions]
        return;
      }

      this.selectedDate = option

      this.optionsRegions = this.optionsRegions.filter((region: any) => {
        return ConvertDate(region.datetime) === option
      })
    },
    selectRegion(option: any) {
      if (option === 'CLEAR_SELECTED') {
        this.selectedRegion = null
        return;
      }

      this.selectedRegion = option
    },
    cancelChanges() {
      this.editMode = false;
      this.setVehicleInfo(this.vehicle);
    },
    async changeEditMode() {
      this.showError = false;
      if (this.editMode) {
        const result = {
          brandCustom: this.vehicle.brandCustom,
          id: this.vehicle.id,
          lastUpdate: this.vehicle.lastUpdate,
          supplier: this.vehicle.supplier,
          tecdocVehicleId: this.vehicle.tecdocVehicleId,
          vehCstTermNo: this.vehicle.vehCstTermNo,
          vehicleFull: this.vehicle.vehicleFull,
          vehicleSort: this.vehicle.vehicleSort,
          modelId: this.vehicleInfoEdit.modelId ? this.vehicleInfoEdit.modelId : this.vehicle.modelId,
          customDesc: this.vehicleInfoEdit.customDesc ? this.vehicleInfoEdit.customDesc : '',
          tecdocId: this.vehicleInfoEdit.tecdocVehicleId ? Number(this.vehicleInfoEdit.tecdocVehicleId) : '',
          motorCode: this.vehicleInfoEdit.motorCode || '',
          desc: {
            ...this.vehicle.desc,
            modBodyNum: this.vehicleInfoEdit.modBodyNum ? this.vehicleInfoEdit.modBodyNum : '',
            vehYears: this.vehicleInfoEdit.vehYears ? this.vehicleInfoEdit.vehYears : '',
            bodyType: this.vehicleInfoEdit.bodyType ? this.vehicleInfoEdit.bodyType : '',
            driveType: this.vehicleInfoEdit.driveType ? this.vehicleInfoEdit.driveType : '',
            enginesL: this.vehicleInfoEdit.enginesL ? this.vehicleInfoEdit.enginesL : '',
            fuelType: this.vehicleInfoEdit.fuelType ? this.vehicleInfoEdit.fuelType : '',
            hp: this.vehicleInfoEdit.hp ? this.vehicleInfoEdit.hp : '',
          },
        };
        this.isLoading = true;

        await vehicles.updateVehicle(result.id, result).then((res) => this.vehicle = res);
        this.setVehicleInfo(this.vehicle)

        if (this.vehicleInfoEdit.brandCustom && this.vehicleInfoEdit.brandCustom.length) {
          const { supplier } = result;
          const { brand, braCstTermNo } = this.vehicle.model.brand;

          const payload = {
            tecdocId: supplier.id,
            brand,
            displayName: brand,
            braCstTermNo,
            visible: true,
            brandCustom: this.vehicleInfoEdit.brandCustom,
          };

          vehicleBrands.updateBrand(this.vehicle.model.brand.id, payload).then((res: any) => {
            this.vehicle.brandCustom = res.brandCustom;
          });
        }
        this.isLoading = false;
      }
      this.editMode = !this.editMode;
    },
    setActiveTab(item: any) {
      routerService.setQuery(
        'content',
        item.key,
        this.$route.query,
        this.$router
      )
      this.activeTab = item;
    },
    async updateInfoVehicle() {
      if (this.$route.params.carId) {
        const vehicle = await vehicles.findById(`${this.$route.params.carId}`) as any
        const vehicleBrand = await vehicleBrands.findBrandById(vehicle.model.brand.id) as any
        this.vehicle = {...vehicle, brandCustom: vehicleBrand.brandCustom }
        this.setVehicleInfo(this.vehicle);

        this.allModelsCurrentBrand = await vehicleModels.getModelsByBrandId(this.vehicle.model.brand.id)

        const articlesByVehicles = await vehicles.getArticlesByVehicles(`${this.$route.params.carId}`)
        this.vehicleArticles = articlesByVehicles.sort(this.sortByProductGroups)
      }
    },
    sortByProductGroups(a: any, b: any) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }

      return 0;
    },
    setVehicleInfo(vehicle: any) {
      this.vehicleInfoEdit = {
        ...this.vehicleInfoEdit,
        modelId: vehicle.model.id,
        modelFull: vehicle.model.modelFull,
        vehYears: vehicle.desc.vehYears,
        bodyType: vehicle.desc.bodyType,
        driveType: vehicle.desc.driveType,
        enginesL: vehicle.desc.enginesL,
        fuelType: vehicle.desc.fuelType,
        hp: vehicle.desc.hp,
        modBodyNum: vehicle.desc.modBodyNum,
        // vehicle: vehicle.vehicle,
        brandCustom: vehicle.brandCustom,
        customDesc: vehicle.customDesc,
        tecdocVehicleId: vehicle?.tecdocVehicleId,
        model: vehicle.model.model,
        motorCode: vehicle.motor
      };
    },
  },
  beforeUnmount() {
    this.$store.commit(VehicleMutationTypes.SET_VEHICLES,[])
  },
  async mounted() {
    await this.updateInfoVehicle();

    this.allCountries = await country.getCountryByName()
    this.allRegions = [...this.vehicle.regionsCount]

    this.optionsCountry = this.allCountries.filter((country: any) => {
      const indexCountry = this.allRegions.findIndex((region: any) => region.countryId === country.id)

      return indexCountry >= 0 && this.allCountries[indexCountry]
    })

    await this.$store.dispatch("fetchImages", this.vehicle.id)

    if (!this.$route.query.content) {
      await routerService.setQuery(
        'content',
        this.activeTab.key,
        this.$route.query,
        this.$router
      )
    }
  },
});
