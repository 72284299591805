
import {defineComponent} from 'vue';
import ToggleItem from '@/components/commodity-groups/ToggleItem.vue';

export default defineComponent({
  components: { ToggleItem },
  name: 'BaseTable',
  emits: ['onClickContentRow'],
  props: {
    view: {
      type: String,
      default: () => 'default'
    },
    headerItems: {
      type: Array,
      required: true,
      default: () => null
    },
    contentItems: {
      type: Array,
      required: true,
      default: () => null
    },
    subContentItems: {
      type: Array,
      required: false,
      default: () => null
    },
    isShowArrow: Boolean,
    colorCell: String
  },
  computed: {
    classListBaseTableCell(): any {
      return (item: any) => {
        return [
          'base-table__cell',
          item.colorCell && `base-table__cell_${item.colorCell}`
        ]
      }
    },
    classList(): any {
      return [
        'base-table',
        `base-table__view_${this.view}`
      ]
    },
  },
  methods: {
    handlerClickRow(item: any) {
      this.$emit('onClickContentRow', item)
    }
  }
})
