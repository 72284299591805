export default function () {
  let timeoutRef: any = null;
  const debounce = (fnc: any, e: any, delayMs: number = 500) => {
    if (timeoutRef !== null) clearTimeout(timeoutRef);
    timeoutRef = setTimeout(() => {
      fnc(e)
    }, delayMs || 500)
  };
  return {debounce}
}
