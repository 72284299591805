
import { defineComponent } from 'vue';
import { Status } from '@/components/types/enums';
import BaseTable from '@/components/ui/BaseTable.vue';
import DropdownActions from '@/components/article-card/history/DropdownActions.vue';
import vehicles from '@/services/vehicles';
import routerService from '@/services/routerService';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import configuration from '../../../configuration';

const CLEAR_FILTER = 'Очистить выбор'

export default defineComponent({
  name: 'VehicleParts',
  components: {
    BaseIcon,
    BaseTable,
    DropdownActions
  },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    filter: {} as any
  }),
  computed: {
    customer() {
      return configuration.customer
    },
    selectedOption() {
      return (fieldName: any) => {
        const currentOption = this.filter[fieldName] && this.filter[fieldName][0]

        return fieldName === 'status'
          ? this.convertStatus(currentOption)
          : currentOption
      }
    },
    convertStatus(status: any) {
      return (status: any) => {
        const statuses: any = Status
        return statuses[status]
      }
    },
    getOptionsFilter(): any {
      return (field: any) => {

        const options = this.items.map((item: any) => {
          if (field === 'status') {
            return this.convertStatus(item.status)
          }

          return item[field]
        })

        return [
          CLEAR_FILTER,
          ...new Set(options)
        ]
      }
    },
    settingsForFilters(): any {
      return [
        {
          title: 'Название',
          fieldName: 'name',
        },
        {
          title: 'Артикул',
          fieldName: 'article',
        },
        {
          title: 'Статус',
          fieldName: 'status',
        }
      ]
    },
    getLinkForDownloadVehicleArticles(): string {
      return vehicles.articlesByVehicleToFile(this.$route.params.carId);
    },
    isSelectedArticle() {
      return ({ articleId }: any) => {
        return this.$route.query['last-selected-article'] === String(articleId)
      }
    },
    headerAutoPartsTable() {
      return [
        {
          title: 'Название',
          content: (item: any) => item.name
        },
        {
          title: 'Артикул',
          content: (item: any) => item.article
        },
        {
          title: 'Статус',
          content: (item: any) => this.convertStatus(item.status)
        }
      ]
    }
  },
  methods: {
    setOptionFilter(option: any, fieldName: any) {
      if (option.title === CLEAR_FILTER) {
        this.filter[fieldName] = []
        return;
      }
      if (fieldName === 'status') {
        const statuses: any = Status
        const convertData = Object.entries(statuses)
        const index = convertData.findIndex(([key, value]) => value === option.title)
        this.filter[fieldName] = [convertData[index][0]]
        return
      }

      this.filter[fieldName] = [option.title]
    },
    async goToPage({ articleId }: any) {
      await routerService.setQuery(
        'last-selected-article',
        articleId,
        this.$route.query,
        this.$router
      )
      await this.$router.push({
        name: 'article',
        params: {
          id: articleId
        }
      })
    },
    filterData(data: any, query: any) {
      return data.filter((item: any) => {
        for (const key in query) {
          if (item[key] === undefined || !query[key].includes(item[key])) {
            return false;
          }
        }
        return true;
      });
    },
    buildFilter(filter: any) {
      const query = {} as any;
      for (const key in filter) {
        if (filter[key].constructor === Array && filter[key].length > 0) {
          query[key] = filter[key];
        }
      }
      return query;
    },
  },
});
