
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'SelectionSlot',
  emits: ['action-modal', 'close-modal'],
  props: {
    text: {
      type: String,
      default: () => ''
    }
  }
})
