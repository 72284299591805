import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, renderSlot as _renderSlot, createVNode as _createVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "base-table__main" }
const _hoisted_2 = { class: "base-table__header" }
const _hoisted_3 = { class: "base-table__row" }
const _hoisted_4 = { class: "base-table__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToggleItem = _resolveComponent("ToggleItem")

  return (_openBlock(), _createBlock("div", { class: _ctx.classList }, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode("div", _hoisted_3, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.headerItems, (header, indexHeaderItem) => {
            return (_openBlock(), _createBlock("div", {
              class: "base-table__cell",
              key: indexHeaderItem
            }, [
              _renderSlot(_ctx.$slots, "header", {
                headerItem: header,
                indexColumn: indexHeaderItem
              })
            ]))
          }), 128))
        ])
      ]),
      _createVNode("div", _hoisted_4, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.contentItems, (contentItem, indexContentItem) => {
          return (_openBlock(), _createBlock(_component_ToggleItem, {
            key: indexContentItem,
            showArrow: _ctx.isShowArrow
          }, {
            content: _withCtx(({isListOpen}) => [
              _createVNode("div", {
                class: "base-table__row",
                onClick: ($event: any) => (_ctx.handlerClickRow(contentItem))
              }, [
                (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.headerItems, (header, indexHeaderItem) => {
                  return (_openBlock(), _createBlock("div", {
                    class: _ctx.classListBaseTableCell(contentItem),
                    key: indexHeaderItem
                  }, [
                    _renderSlot(_ctx.$slots, "content", {
                      item: contentItem,
                      headerItem: header,
                      indexColumn: indexHeaderItem,
                      indexRow: indexContentItem,
                      isListOpen: isListOpen
                    })
                  ], 2))
                }), 128))
              ], 8, ["onClick"]),
              isListOpen
                ? (_openBlock(true), _createBlock(_Fragment, { key: 0 }, _renderList(contentItem.articles, (subContentItem, indexSubContentItem) => {
                    return (_openBlock(), _createBlock("div", {
                      class: "base-table__row",
                      key: indexSubContentItem
                    }, [
                      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.headerItems, (header, indexHeaderItem) => {
                        return (_openBlock(), _createBlock("div", {
                          class: "base-table__cell",
                          key: indexHeaderItem
                        }, [
                          _renderSlot(_ctx.$slots, "subContent", {
                            item: subContentItem,
                            headerItem: header,
                            indexColumn: indexHeaderItem,
                            indexRow: indexContentItem,
                            isListOpen: isListOpen
                          })
                        ]))
                      }), 128))
                    ]))
                  }), 128))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["showArrow"]))
        }), 128))
      ])
    ])
  ], 2))
}