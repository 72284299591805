
import {defineComponent, ref, computed, watch, onMounted} from 'vue';

export default defineComponent({
  name: 'DropdownActions',
  props: {
    options: {
      type: Array,
      required: true,
      default: () => null
    },
    title: {
      type: String,
      required: false
    },
    pickedOption: {
      type: String,
      default: () => null
    },
    type: {
      type: String,
      required: false,
      default: () => null
    }
  },
  data: () => ({
    isDropdownRevealed: false as boolean,
    pickedDropdownOption: null
  }),
  computed: {
    getOptionName() {
      return (option: any) => {
        if (this.type === 'MODEL_FULL') {
          return option.model
        }

        if (this.type === 'MANUFACTURER') {
          return option.name
        }

        return option
      }
    }
  },
  methods: {
    setOption(option: any) {
      let filter

      switch (this.title) {
        case 'Изменения':
          filter = 'CHANGES'
          break;
        case 'Автор':
          filter = 'AUTHOR'
          break;
      }
      if (this.type === 'MANUFACTURER') {
        this.$emit('setOption', option)
      } else {
        this.$emit('setOption', { title: option, filter })
      }

      this.pickedDropdownOption = option
      this.isDropdownRevealed = false
    }
  }
})
