export function ConvertDate(editDate: number | string) {
  const date = new Date(editDate);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${day < 10 ? `0${day}` : day}.${month < 10 ? `0${month}` : month}.${year}`
}

export function ConvertDateWithTime(editDate: number) {
  const date = new Date(editDate);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const hours = date.getHours()
  const minutes = date.getMinutes()

  return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}, ${day < 10 ? `0${day}` : day}.${month < 10 ? `0${month}` : month}.${year}`
}

export function convertSimpleDate(date: Date) {
  let year = String(date.getFullYear());
  let month = date.getMonth() + 1 < 10 ? '0' + String(date.getMonth() + 1) : String(date.getMonth() + 1);
  let day = date.getDate() < 10 ? '0' + String(date.getDate()) : String(date.getDate());
  let hours = date.getHours() < 10 ? '0' + String(date.getHours()) : String(date.getHours());
  let minutes = date.getMinutes() < 10 ? '0' + String(date.getMinutes()) : String(date.getMinutes());
  let seconds = date.getSeconds() < 10 ? '0' + String(date.getSeconds()) : String(date.getSeconds());

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}
