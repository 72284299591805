
import {defineComponent, computed} from 'vue';

export default defineComponent({
  name: 'BaseIcon',
  props: {
    type: {
      type: String,
      required: true,
      default: () => null
    },
    method: Function
  },
  setup(props) {
    const iconClass = computed(() => ['base-icon', `base-icon--${props.type}`]);

    function buttonClick() {
      if (props.method) props.method()
    }

    return {iconClass, buttonClick}
  }
})
